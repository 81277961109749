import PropTypes from 'prop-types'
import { formatMoney } from "utils/money"

export default function DoToday({ total, labelClassName, contentClassName }) {
  return (
    <div className="flex justify-between items-center font-sourceserif4 text-xl">
      <h2 className={`tracking-normal ${labelClassName}`}>{`Due Today:`}</h2>
      <p className={`${contentClassName}`} data-testid="due-today-cost">{formatMoney( total )}</p>
    </div>
  )
}

DoToday.propTypes = {
  total: PropTypes.number.isRequired,
  labelClassName: PropTypes.string,
  contentClassName: PropTypes.string
}

DoToday.defaultProps = {
  labelClassName: ``,
  contentClassName: ``
}