import { useEffect } from 'react'
import { postCgmEarlyExit } from './fetchPromises'

export default function Exit({ ...pageProps }) {
  const patientId =
    pageProps?.productPricingData?.data?.patient?.patient_id
  useEffect( () => {
    const controller = new AbortController()
    postCgmEarlyExit( controller.signal, patientId, `No reader no RX` )
  }, [] )

  return (
    <div className="m-8 text-center lg:pr-8 lg:w-1/2 lg:mx-auto ">
      <h1 className="text-xl text-center font-sourceserif4">{`Your prescription does not include a reader.`}</h1>
      <div className="text-left mt-4">
        <p className="text-xs mb-2">
          {`Because your original prescription does not include a reader, we cannot continue your order for a new reader, at this moment. So here are our next steps:`}
        </p>
        <ol className="list-decimal list-inside text-xs mb-2.5">
          <li>{`We will reach out to your doctor to get an updated prescription.`}</li>
          <li>{`We will contact you when we can continue with your order.`}</li>
        </ol>
        <p className="text-xs mb-2">
          {`Simple as that! This process can take anywhere from 24 hours to 10 days. `}
          <strong>
            {`It moves faster when you call your doctor’s office to let them know we’ll be reaching out for an updated prescription.`}
          </strong>
        </p>
        <p className="text-xs">
          {`Please feel free to call us at `}
          <a
            href="tel:18884566755"
            className="text-indigo underline"
          >{`1-866-456-6755.`}</a>
          {` with any questions or concerns.`}
        </p>
      </div>
    </div>
  )
}
