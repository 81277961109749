import { RadioCheckboxInputCustom } from "components/inputs/components"
import { NavButton } from "components/navButton"
import PaymentDetails from "components/payments/PaymentDetails"
import { useDoctorReferStore } from "modules/doctorRefer/DoctorReferStore"
import { useState } from "react"
import useAddressStore from "state/address"
import usePayMethodsStore from "state/payMethods"
import AobInvoice from "types/aobInvoice"
import PageTitle from "./PageTitle"
import ShippingInfo from "./ShippingInfo"
import './style.css'

type ConfirmShippingProps = {
  nextPage: () => void;
  invoice: AobInvoice;
};

export default function ConfirmShippingAndPayment({ invoice, nextPage }: ConfirmShippingProps ) {
  const [ addressConfirmed, setAddressConfirmed, totalDueToday ] = useDoctorReferStore( ( state ) => [ state.refer.addressConfirmed, state.setAddressConfirmed, state.refer.totalDueToday ] )
  const { currentView } = useAddressStore()

  const isEditingAddress = currentView === `edit`

  const handleOnSuccessfulConfirm = ( ) => {
    if ( error ) setError( null )
  }

  const [ payMethods ] = usePayMethodsStore( ( state ) => [ state.payMethods ] )

  const [ error, setError ] = useState<string | null>( null )

  const handleOnSubmit = ( event: React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()
    if ( isEditingAddress ) return setError( `Please confirm your address` )
    // need to make sure the address is confirmed before moving to the next page
    if ( !addressConfirmed ) {
      return setError( `Please verify your shipping address` )
    }

    if ( ( totalDueToday === 0 && payMethods.length === 0 ) || ( totalDueToday > 0 && payMethods.length > 0 ) ) return nextPage()

    return setError( `Please add a payment method` )
  }

  const handleRadioChange = ( ) => {
    setAddressConfirmed( !addressConfirmed )
    if ( error ) setError( null )
  }

  return (
    <>
      <div className="mx-8 lg:w-[600px]">
        <PageTitle className="hidden lg:block mt-0" />
        <hr className="border-skyBlue2 mb-6 lg:hidden" />
        <div className="mb-6">
          <h2 className="text-xl md:text-2xl font-sourceserif4 mb-4">
            {`Confirm Your Shipping Address`}
          </h2>
          <ShippingInfo
            onSuccessfulConfirm={handleOnSuccessfulConfirm}
          />
        </div>
        <form onSubmit={handleOnSubmit}>
          <RadioCheckboxInputCustom
            type="checkbox"
            label={`I verify that this is my current shipping address`}
            name="confirmAddress"
            labelClassName="leading-4"
            data-testid="confirm-shipping-address"
            onChange={handleRadioChange}
            checked={addressConfirmed}
            wrapperClassName={`mb-4 && ${error && !addressConfirmed ? `border border-red-500 p-2 ` : ``}`}
          />
          {invoice.total_to_collect !== 0 && <PaymentDetails invoice={invoice} />}

          {error && <p className="text-red-500 text-sm mt-2 mb-4">{error}</p>}
          <NavButton
            options={
              {
                buttonText: `Continue to Review`,
                className: `bg-[#83c6d6] rounded py-2 px-4 w-full text-md disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800 text-base md:text-lg font-normal lg:w-1/2 continue-review`
              }
            }
            data-testid="nav-button-next"
          />
        </form>
      </div>
    </>
  )
}
