import Alert from 'components/alerts/Alert'
import { ProductImage } from 'components/productImage'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import PropTypes from 'prop-types'
import DoctorReferralInvoiceType from 'types/doctorReferralInvoice'

export default function ItemsToShip({ invoice }: DoctorReferralInvoiceType ) {
  return (
    <div className="m-8 lg:w-[400px]">
      <Alert message="For your convenience, we will ship you a 3-month supply of sensors at one time." />
      <div className="lg:pt-2">
        <span className="bg-purple4 rounded-full py-1 px-2 lg:px-4 text-xs md:text-base font-semibold mb-4 inline-block">
          {`Ships Today`}
        </span>
        <div className="mb-4">
          <ul className="space-y-4">
            {invoice?.details
              .filter( ( item: any ) => !item.is_sensor && useDoctorReferStore( ( state: any ) => state.refer.hasReader ) !== true || item.is_sensor )
              .map(
                ( item: { name: string, product_url: string, item_description: string, hcpc_code: string, is_sensor: boolean }) => (
                  <li className="flex items-center" key={item.hcpc_code}>
                    <ProductImage item={item} />
                    <div>
                      <p className="text-xs pl-2" data-testid={`ships-today-rx-item-label-${item.hcpc_code}`}>
                        {item?.item_description}
                        {item?.is_sensor && `- 3 Month Supply`}
                      </p>
                    </div>
                  </li>
                )
              )}
          </ul>
        </div>
        <div>
          <span className="bg-purple4 rounded-full py-1 px-2 lg:px-4 text-xs md:text-base font-semibold mb-4 inline-block">
            {`Ships Every 3 Months`}
          </span>
          <ul className="space-y-4">
            {invoice?.details.map(
              ( item: { name: string, product_url: string, item_description: string, hcpc_code: string, is_sensor: boolean }) => (
                item.is_sensor && <li className="flex items-center" key={item.hcpc_code}>
                  <ProductImage item={item} />
                  <div>
                    <p className="text-xs pl-2" data-testid={`ships-every-three-months-rx-item-label-${item.hcpc_code}`}>
                      {item?.item_description}
                      {item?.is_sensor && `- 3 Month Supply`}
                    </p>
                  </div>
                </li>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  )
}

// Add prop validation for 'invoice'
ItemsToShip.propTypes = {
  invoice: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    )
  }).isRequired
}