import { formatMoney } from "utils/money"

export default function OngoingMonthlyCost({total}) {

  return (
    <div className="lg: border-skyBlue2 lg:border-l-[1px] lg:pl-2">
      <h2 className="text-xs md:text-base font-semibold mb-1 tracking-normal">{`Ongoing Monthly Cost:`}</h2>
      <div className="flex gap-2 items-center relative">
        <p className="font-sourceserif4 text-xl md:text-2xl" data-testid="ongoing-monthly-cost">{formatMoney( total )}</p>
        <div className="tooltip alt" role="tooltip">
          <svg
            width="20" height="20" viewBox="0 0 20 20"
            fill="none" className="cursor-pointer" xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2.5" y="2.5" width="15"
              height="15" rx="7.5" stroke="#222222"
            />
            <path d="M9.10204 12.1702V12.1128C9.10884 11.5041 9.17687 11.0198 9.30612 10.6597C9.43537 10.2996 9.61905 10.008 9.85714 9.78489C10.0952 9.56182 10.381 9.35628 10.7143 9.16826C10.915 9.05354 11.0952 8.9181 11.2551 8.76195C11.415 8.60261 11.5408 8.41937 11.6327 8.21224C11.7279 8.0051 11.7755 7.77565 11.7755 7.5239C11.7755 7.2116 11.6973 6.94073 11.5408 6.71128C11.3844 6.48184 11.1752 6.30497 10.9133 6.18069C10.6514 6.0564 10.3605 5.99426 10.0408 5.99426C9.7619 5.99426 9.4932 6.04844 9.23469 6.15679C8.97619 6.26514 8.7602 6.43563 8.58673 6.66826C8.41327 6.90089 8.31293 7.20523 8.28571 7.58126H7C7.02721 7.03952 7.17687 6.57584 7.44898 6.19025C7.72449 5.80465 8.08673 5.50988 8.53571 5.30593C8.9881 5.10198 9.4898 5 10.0408 5C10.6395 5 11.1599 5.11154 11.602 5.33461C12.0476 5.55768 12.3912 5.86361 12.6327 6.25239C12.8776 6.64117 13 7.08413 13 7.58126C13 7.9318 12.9422 8.24888 12.8265 8.53251C12.7143 8.81612 12.551 9.06947 12.3367 9.29254C12.1259 9.51561 11.8707 9.71319 11.5714 9.88528C11.2721 10.0605 11.0323 10.2454 10.852 10.4398C10.6718 10.631 10.5408 10.8588 10.4592 11.1233C10.3776 11.3878 10.3333 11.7177 10.3265 12.1128V12.1702H9.10204ZM9.7551 15C9.5034 15 9.28741 14.9156 9.10714 14.7467C8.92687 14.5778 8.83673 14.3754 8.83673 14.1396C8.83673 13.9038 8.92687 13.7014 9.10714 13.5325C9.28741 13.3636 9.5034 13.2792 9.7551 13.2792C10.0068 13.2792 10.2228 13.3636 10.4031 13.5325C10.5833 13.7014 10.6735 13.9038 10.6735 14.1396C10.6735 14.2957 10.631 14.4391 10.5459 14.5698C10.4643 14.7004 10.3537 14.8056 10.2143 14.8853C10.0782 14.9618 9.92517 15 9.7551 15Z" fill="#222222" />
          </svg>
          <span>{`The ongoing monthly cost is what you will be billed monthly by Aeroflow Diabetes until you cancel.`}</span>
        </div>
      </div>
    </div>
  )
}