import { useEffect, useState } from 'react'
import { reportToSentry } from 'utils/reportToSentry'
import { fetchPatientInvoice, fetchTermsAndConditions } from '../fetchPromises'
import { useNavigate } from 'react-router-dom'
import getHash from 'utils/getHash'
import { UNAUTHORIZED_PAGE_PATH } from 'routes'
import { useAddressStore } from 'state/address'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import { datadogRum } from '@datadog/browser-rum'

function useRetrieveAOBData( setInvoice, setError ) {

  const [ allowPayment, setAllowPayment ] = useState( true )
  const [ aobContent, setAobContent ] = useState( undefined )
  const { address, setAddress } = useAddressStore()
  const [
    hasReader,
    provider,
    readerCost,
    setReaderCost,
    setSensorCost,
    setTotalDueToday
  ] = useDoctorReferStore( state => [
    state.refer.hasReader,
    state.refer.provider,
    state.refer.readerCost,
    state.setReaderCost,
    state.setSensorCost,
    state.setTotalDueToday
  ] )

  const navigate = useNavigate()

  const patientHash = getHash()


  useEffect( () => {
    const controller = new AbortController()
    const {signal} = controller

    const invoiceControl = localStorage.getItem( `invoice-control` ) ? JSON.parse( localStorage.getItem( `invoice-control` ) ) : null

    // check if the invoice has already been fetched once in the users login session
    if ( invoiceControl !== null && !invoiceControl?.fetchEnabled && invoiceControl?.data && patientHash === invoiceControl?.patientHash ) {
      setInvoice( invoiceControl.data )
      setPatientCosts( invoiceControl.data )
      setAddressFromInvoice( invoiceControl.data )
    } else if ( patientHash ) fetchInvoiceHandler( patientHash, signal )

    return () => {
      controller.abort()
    }
  }, [] )

  useEffect( () => {
    const controller = new AbortController()

    if ( !patientHash ) return navigate( UNAUTHORIZED_PAGE_PATH )

    fetchTermsAndConditions( controller.signal )
      .then( data => {
        const aob_content = data?.data?.aob_content ?? data?.[0]?.aob_content
        if ( aob_content ) setAobContent( aob_content )
        else setAobContent( null )
      })
      .catch( error => {
        if ( error.name !== `AbortError` ) {
          reportToSentry( error )
          setAobContent( null )
        }
      })

    return () => {
      controller.abort()
    }
  }, [] )

  const fetchInvoiceHandler = ( patientHash, signal ) => {
    fetchPatientInvoice( patientHash, signal ).then( ( data ) => {
      // check if we got eligible invoice back from response and if not assume invoice is eligible
      if ( data?.data && data?.meta?.status === `OK` ) {
        const invoice = data.data
        setInvoice( invoice )
        localStorage.setItem( `invoice-control`, JSON.stringify({
          patientHash,
          fetchEnabled: false,
          data: data.data
        }) )
        setPatientCosts( invoice )
        setAddressFromInvoice( invoice )
      } else {
        if ( data?.errors?.length && ( data.errors[0]?.message === `invoice found but not eligible for payment` || data.errors[0]?.message === `invoice not found` ) ) {
          setAllowPayment( false )
        } else {
          setError( `Oops... we encountered an error when attempting to get your invoice details.` )
          datadogRum.addError( `Error fetching invoice data` )
        }
      }
    })
      .catch( error => {
        if ( error.name !== `AbortError` ) {
          datadogRum.addError( error )
          reportToSentry( error, {
            patientHash
          })
          setError( `Oops... we encountered an error when attempting to get your invoice details.` )
        }
      })
  }

  function setPatientCosts( invoice ) {
    const payLaterEligible = invoice?.pay_later_eligible_flag

    const totalToCollect = invoice?.total_to_collect

    if ( payLaterEligible || provider.length > 0 ) {
      // if they are eligible to pay later, we don't need to collect anything today whether they have a reader or not
      setTotalDueToday( 0 )
    } else {
      setTotalDueToday(
        hasReader ? totalToCollect - readerCost : totalToCollect
      )
    }
    setReaderCost( provider.length > 0 ? 0 : invoice?.details?.filter( detail => !detail.is_sensor )?.[0]
      ?.patient_owes || 0 )

    setSensorCost(
      provider.length > 0 ? 0 : ( invoice?.details?.filter( detail => detail.is_sensor )?.[0]?.patient_owes || 0 )
    )
  }

  function setAddressFromInvoice( invoice ) {
    if ( address?.street?.length === 0 ) { // This condition covers the case that the user refreshes or goes back after entering their address
      setAddress({
        firstName: invoice?.first_name ?? ``,
        lastName: invoice?.last_name ?? ``,
        street: invoice?.street ?? ``,
        street2: invoice?.street2 ?? ``,
        city: invoice?.city ?? ``,
        state: invoice?.state ?? ``,
        zipCode: invoice?.zip ?? ``
      })
    }
  }

  return {
    allowPayment,
    aobContent
  }
}

export default useRetrieveAOBData