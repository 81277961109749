import { getAuth } from '../aobPayments/fetchPromises'

export const fetchProductPricing = async ( signal = undefined ) => {
  const response = await fetch ( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/product-pricing`, {
    headers: {
      Authorization: getAuth()
    },
    ...( signal ? {
      signal
    } : {})
  })

  return await response.json()
}

export const postPrescriptionConfirmation = async ( signal, patientId, hasReceiver ) => {
  const response = await fetch ( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/nonweb-referred`, {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      Authorization: getAuth()
    },
    body: JSON.stringify({
      patient_id: patientId,
      cgmreceiver: hasReceiver
    }),
    signal: signal
  })

  return await response.json()
}

export const fetchRxStatus = async ( patientId, signal ) => {
  const response = await fetch ( `https://resupply.aeroflow.ninja/rx-status/${patientId}`, {
    headers: {
      Authorization: getAuth()
    },
    signal
  })

  return await response.json()
}

export const postCgmEarlyExit = async ( signal, patientId, exitMessage ) => {
  const response = await fetch ( `${process.env.REACT_APP_CURATED_URL}/cgm/exit`, {
    method: `POST`,
    headers: {
      Authorization: getAuth(),
      'Content-Type': `application/json`
    },
    body: JSON.stringify({
      patient_id: patientId,
      exit_message: exitMessage
    }),
    signal
  })

  return await response.json()
}