
import AddressBlock, { useAddressStyleStore } from 'components/checkoutReview/addressBlock'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useAddressStore } from 'state'

type patientShippingProps = {
  className?: string;
  showBorder?: boolean;
  allowEdit?: boolean;
  onSuccessfulConfirm?: () => void;
}

export default function ShippingInfo({
  className,
  showBorder = false,
  allowEdit = true,
  onSuccessfulConfirm
}: patientShippingProps ) {

  const { address } = useAddressStore()

  const { setStyleOverrides } = useAddressStyleStore()

  useEffect( () => {
    setStyleOverrides({
      wrapperStyle: `text-left mt-3 text-sm w-full`,
      textInputClassName: `rounded-md input mt-2 mb-3 border border-[#BCBCBC]`,
      buttonClassName: `bg-[#83c6d6] rounded py-2 px-4 w-full text-md disabled:bg-[#e0e0e0] disabled:cursor-not-allowed disabled:text-gray-800 text-base md:text-lg font-normal lg:w-1/2`,
      headerStyle: `hidden`,
      ctaStyle: `text-[#373366] underline decoration-[##373366] cursor-pointer underline-offset-2`,
      containerStyle: `text-left text-sm flex flex-col`,
      view: {
        textStyle: `text-base capitalize`
      }
    })
  }, [] )

  const patientName = `${address.firstName} ${address.lastName}`

  return (
    <div className={`shipping-info ${className ? className : ``}`}>
      <div className={`${showBorder ? `rounded border-2 lg:border border-[#A2D4E0] p-4 lg:p-8` : ``}`}>
        <div className={`flex gap-2 mb-2 shipping-to-patient`}>
          <span className="font-semibold">
            {`Name:`}
          </span>
          <span data-testid="shipping-info-patient-name">
            {patientName}
          </span>
        </div>
        <div className="mb-2 shipping-to-address">
          <div className="lg:mb-2">
            <AddressBlock
              shouldSkipMountFetch // we will have fetched the invoice and set the address there already
              hideAddressConfirm
              hideName
              headerText=""
              hideEdit={!allowEdit}
              onSuccessfulConfirm={onSuccessfulConfirm}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ShippingInfo.propTypes = {
  patientName: PropTypes.string,
  patientAddress: PropTypes.shape({
    street: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    city: PropTypes.string
  }),
  className: PropTypes.string,
  showBorder: PropTypes.bool
}
