import Header from 'components/checkout/Header'
import PageTitle from 'components/checkout/PageTitle'
import ShippingInfo from 'components/checkout/ShippingInfo'
import AcceptTOC from 'components/checkoutReview/AcceptTOC'
import { PrescriptionDetails } from 'components/prescriptionDetails'
import { YourCosts } from 'components/yourCosts'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import { CHECKOUT_PATH } from 'modules/doctorRefer/constants'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import getHash from 'utils/getHash'

export default function CheckoutReview({ ...pageProps }) {
  const navigate = useNavigate()
  const [ isLoading, setIsLoading ] = useState( false )
  const [
    pageLoadCount,
    addressConfirmed,
    hasAcceptedTerms
  ] = useDoctorReferStore(
    ( state: any ) => [
      state.refer.pageLoadCount,
      state.refer.addressConfirmed,
      state.refer.hasAcceptedTerms
    ]
  )

  if ( !addressConfirmed && !hasAcceptedTerms || pageLoadCount > 1 ) {
    navigate( `${CHECKOUT_PATH}?sgh=${getHash()}` )
  }

  if ( isLoading ) {
    return (
      <div className="w-full h-full bg-white opacity-75 absolute inset-0 z-100 flex justify-center items-center" data-testid="loading-overlay">
        <span>{`Loading...`}</span>
      </div>
    )
  }

  return (
    <>
      <Header prevPage={pageProps.prevPage} />

      <div className="relative lg:px-8 px-0 lg:max-w-[1110px] mx-auto">
        <PageTitle className="lg:w-1/2" />
        <h2 className="font-sourceserif4 text-xl mx-8 my-6 lg:mx-0 lg:w-1/2">
          {`Review Your Order`}
        </h2>
        <div className="lg:flex lg:gap-10 lg:justify-between">
          <div className="lg:w-3/5">
            <PrescriptionDetails
              items={pageProps?.invoice?.details}
              doctorInfo={pageProps?.productPricingData?.data?.patient} invoice={pageProps?.invoice}
              hidePrescription
              showProductDetails
            />
            <ShippingInfo
              className={`mt-0 pb-8 lg:mx-0 lg:px-0 px-8`}
              showBorder
              allowEdit
            />
          </div>
          <div className="lg:w-2/5">
            <YourCosts
              invoice={pageProps?.invoice}
              error={pageProps?.error}
              hideBreakdown
              nextPage={pageProps?.nextPage}
            />
            <AcceptTOC nextPage={pageProps?.nextPage} setIsLoading={setIsLoading} invoice={pageProps?.invoice} />
          </div>
        </div>
      </div>
    </>
  )
}
