type NavButtonProps = {
  // eslint-disable-next-line no-unused-vars
  options: {
    hasReceiver?: boolean,
    className?: string,
    buttonText: string
  }
}

export default function NavButton({ options, ...props }: NavButtonProps ) {
  return (
    <button
      className={options.className}
      type="submit"
      {...props}
    >
      <span>
        {options.buttonText}
      </span>
    </button>
  )
}