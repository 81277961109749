import ItemsToShip from 'components/checkout/ItemsToShip'
import Nav from 'components/checkout/Nav'
import ShippingInfo from 'components/checkout/ShippingInfo'
import { EmailCollect } from 'components/emailCollect'
import { useDoctorReferStore } from 'modules/doctorRefer/DoctorReferStore'
import { SUMMARY_PATH } from 'modules/doctorRefer/constants'
import { useFetchRxStatus } from 'modules/doctorRefer/customHooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import getHash from 'utils/getHash'
import './style.css'

export default function OrderConfirmation({ ...pageProps }) {
  window.scrollTo({
    top: 0,
    behavior: `smooth`
  })
  const navigate = useNavigate()
  const [
    pageLoadCount,
    orderConfirmation,
    notInterested,
    setOrderComplete,
    setPageLoadCount
  ] = useDoctorReferStore( ( state: any ) => [
    state.refer.pageLoadCount,
    state.refer.orderConfirmation,
    state.refer.notInterested,
    state.setOrderComplete,
    state.setPageLoadCount
  ] )

  if ( !orderConfirmation || notInterested || pageLoadCount > 1 ) {
    navigate( `${SUMMARY_PATH}?sgh=${getHash()}` )
  }
  const patientId = pageProps?.productPricingData?.data?.patient?.patient_id

  const { rxStatus, loading } = useFetchRxStatus( patientId ) as {rxStatus: any, loading: boolean}

  const isEmailValid = rxStatus?.data?.valid_eml

  useEffect( () => {
    setOrderComplete( true )
    setPageLoadCount( pageLoadCount + 1 )
  }, [] )

  return (
    <div className="order-confirmation">
      <Nav prevPage={pageProps.prevPage} onlyLogo />
      <div className="p-8 md:p-0">
        <h1 className="font-sourceserif4 text-center text-2xl md:text-3xl mb-12">
          <span className="capitalize" data-testid="patient-first-name">
            {`${pageProps.invoice?.first_name.toLowerCase()},`}
          </span>
          <br />
          {`your order has been placed!`}
        </h1>
        {
          loading ? (
            <div className="text-center">
              <p className="font-sourceserif4 text-xs md:text-xl" data-testid="loading-overlay">
                {`Loading...`}
              </p>
            </div>
          )
            :
            isEmailValid ? (
              <div className="text-center my-12 px-5 md:px-8 py-16 bg-skyBlue">
                <h2 className="font-sourceserif4 leading-none mb-4 text-lg md:text-2xl">
                  {`Thank you!`}
                </h2>
                <p className="font-sourceserif4 text-xs md:text-xl">
                  {`Look out for order tracking and reminder emails from Aeroflow Diabetes.`}
                </p>
              </div>
            )
              :
              <EmailCollect />
        }
        <ItemsToShip invoice={pageProps.invoice} />
        <ShippingInfo allowEdit={false} />
      </div>
    </div>
  )
}
