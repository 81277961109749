import { reportToSentry } from 'utils/reportToSentry'
import getHash from 'utils/getHash'

export function getAuth(){
  const urlParams = new URLSearchParams( window.location.search )
  const patientHash = urlParams.get( `sgh` ) ?? urlParams.get( `txt` )


  return `PatientHash ${patientHash}`
}

export const fetchTermsAndConditions = async ( signal ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_ACCEPTANCE_OF_BENEFITS_URL}?profit_center_pk=34`, {
    signal
  })

  return await response.json()
}

export const submitEmergencyContactInfo = async ( emergenctContactFormFields ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/emergency-contact`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      Authorization: getAuth()
    },
    body: JSON.stringify( emergenctContactFormFields )
  })

  return await response.json()
}

export const submitAobAcceptance = async ( profitCenterPk = 5 ) => {
  const response = await fetch( `${process.env.REACT_APP_LAMBDA_ACCEPTANCE_OF_BENEFITS_URL}/accept-from-browser`, {
    mode: `cors`,
    method: `POST`,
    headers: {
      Authorization: getAuth()
    },
    body: JSON.stringify({
      profit_center_pk: profitCenterPk
    })
  })

  return await response.json()
}

export const fetchPatientInvoice = async ( patientHash, signal ) => {
  const response = await fetch ( `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}?profit_center_pk=34`, {
    signal: signal
  })

  return await response.json()
}

export const fetchSubmitNotInterestedReason = async ( reason ) => {
  const response = await fetch( `${process.env.REACT_APP_NOT_INTERESTED_URL}/not-interested`, {
    mode: `cors`,
    method: `POST`,
    body: JSON.stringify({
      notInterestedReason: reason,
      source: `${window.location.hostname}`,
      patientHash: getHash()
    })
  })

  return await response.json()
}

export const finalizeInvoice = async ( patientHash ) => {
  const response = await fetch( `${process.env.REACT_APP_AOB_URL}/invoice/${patientHash}/done`, {
    method: `POST`,
    headers: {
      "Content-Type": `application/json`
    }
  })

  const data = await response.json()

  if ( data?.errors && data.errors?.length && data.errors[0]?.message ) {
    reportToSentry( `CGM AOB: Error finalizing patient invoice`, {
      patientHash,
      message: data.errors[0].message
    })
  } else if ( data?.data?.success !== true ) {
    reportToSentry( `CGM AOB: Error finalizing patient invoice`, {
      patientHash
    })
  }
}


export const updateAddress = ( address ) => {
  const requestInit = {
    method: `POST`,
    headers: {
      'Content-Type': `application/json`,
      'Authorization': getAuth()
    },
    body: JSON.stringify({
      addressType: `shipping`,
      street1: address.address,
      city: address.city,
      state: address.state,
      zip: address.zip
    })
  }

  return fetch( `${process.env.REACT_APP_LAMBDA_PATIENTS_URL}/update-address`, requestInit )
}
